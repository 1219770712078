import React from 'react';
import { AiOutlineSearch } from 'react-icons/ai';
import { Pagination, Row as AntdRow, Col, Button, Input, Table } from 'antd';
import moment from 'moment';
import TableComponent from 'components/table-component';
import AddBilling from 'pages/billings/add-billing';
import DatePicker from 'components/date-picker';
import ItemsModal from './items-modal';
import { DATE_FORMAT } from 'constants/app-constants';

const { RangePicker } = DatePicker;

const BillingsListPresentational = ({
	column,
	filteredData,
	handleTableChange,
	getStartingValue,
	getEndingValue,
	pageSize,
	intialPageSizeOptions,
	initialPageSize,
	currentPage,
	refreshList,
	tableLoading,
	state,
	setState,
	editData,
	setSearchKey,
	selectedRowKeys,
	selectedViewRow,
	setSelectedViewRow,
	showPropsConfirm,
	buttonRef,
	handle,
	setDates,
}) => {
	// Calculate totals for summary
	const totalAmount = filteredData.reduce((sum, record) => sum + record.totalAmount, 0);
	const totalCash = filteredData.reduce((sum, record) => sum + record.cash, 0);
	const totalUPI = filteredData.reduce((sum, record) => sum + record.upi, 0);
	const totalCard = filteredData.reduce((sum, record) => sum + record.card, 0);

	return (
		<AntdRow>
			<Col span={24} style={{ padding: 20 }}>
				<TableComponent
					loading={tableLoading}
					className="custom-table"
					style={{ width: '100%' }}
					rowKey={(record) => record._id}
					dataSource={filteredData}
					title={() => (
						<AntdRow justify="space-between">
							<Col span={12}>
								<AntdRow gutter={[10, 10]}>
									<Col span={24}>
										<AntdRow gutter={[10, 10]} align="middle">
											<Col span={8}>
												<Input
													placeholder="Search"
													suffix={<AiOutlineSearch />}
													onChange={({ target: { value } }) => setSearchKey(value)}
												/>
											</Col>
											<Col span={16}>
												<RangePicker
													allowClear={false}
													format={[DATE_FORMAT.DD_MM_YYYY, DATE_FORMAT.DD_MM_YYYY]}
													defaultValue={[moment(), moment()]}
													onChange={(dates) => {
														setDates({
															startDate: moment(dates[0], DATE_FORMAT.DD_MM_YYYY),
															endDate: moment(dates[1], DATE_FORMAT.DD_MM_YYYY),
														});
													}}
												/>
											</Col>
										</AntdRow>
									</Col>
								</AntdRow>
							</Col>
							<Col>
								<Button
									ref={buttonRef}
									type="primary"
									onClick={() => {
										handle?.enter();
										setState({
											...state,
											visible: true,
										});
									}}>
									Create Billing
								</Button>
							</Col>
						</AntdRow>
					)}
					columns={column}
					scroll={{ x: 'auto' }}
					pagination={{
						current: currentPage,
						pageSize: pageSize,
						position: ['none', 'none'],
					}}
					{...{
						...(!!filteredData?.length && {
							footer: () => (
								<AntdRow justify="space-between">
									<Col md={16}>
										{!!filteredData?.length &&
											`Showing ${getStartingValue()} - ${getEndingValue()} of ${filteredData?.length} Data`}
									</Col>
									<Col md={8}>
										<div style={{ textAlign: 'right' }}>
											<Pagination
												pageSizeOptions={intialPageSizeOptions}
												defaultPageSize={initialPageSize}
												showSizeChanger={true}
												total={filteredData?.length}
												onChange={handleTableChange}
												responsive
											/>
										</div>
									</Col>
								</AntdRow>
							),
						}),
					}}
					summary={(pageData) => {
						let totalPageAmount = 0;

						pageData.forEach(({ totalAmount }) => {
							totalPageAmount += totalAmount;
						});

						return (
							<Table.Summary.Row>
								<Table.Summary.Cell index={0} colSpan={3}>
									<strong>Total Collection:</strong>
								</Table.Summary.Cell>
								<Table.Summary.Cell index={1} align="right">
									<strong>{totalAmount.toFixed(2)}</strong>
								</Table.Summary.Cell>
								<Table.Summary.Cell index={2} align="right">
									<strong>{totalCash.toFixed(2)}</strong>
								</Table.Summary.Cell>
								<Table.Summary.Cell index={3} align="right">
									<strong>{totalUPI.toFixed(2)}</strong>
								</Table.Summary.Cell>
								<Table.Summary.Cell index={4} align="right">
									<strong>{totalCard.toFixed(2)}</strong>
								</Table.Summary.Cell>
								<Table.Summary.Cell index={5} align="right"></Table.Summary.Cell>
								<Table.Summary.Cell index={6} align="right"></Table.Summary.Cell>
								<Table.Summary.Cell index={7} align="right"></Table.Summary.Cell>
							</Table.Summary.Row>
						);
					}}
				/>
			</Col>
			<AddBilling {...{ state, setState, refreshList, editData }} />
			<ItemsModal {...{ setSelectedViewRow, selectedViewRow }} />
		</AntdRow>
	);
};

export default BillingsListPresentational;
