import React, { useState } from 'react';
import { AiOutlineSearch } from 'react-icons/ai';
import moment from 'moment';
import { Pagination, Row, Col, Button, Form, Input, Select, Modal } from 'antd';
import TableComponent from 'components/table-component';
import { DATE_FORMAT } from 'constants/app-constants';
import DatePicker from 'components/date-picker';
import { FileExcelOutlined } from '@ant-design/icons';

const layer1FormCol = {
	labelCol: {
		span: 9,
	},
	wrapperCol: {
		span: 15,
	},
};

const AdditionalReportNewPresentational = ({
	column,
	filteredData,
	getBillingItems,
	getStartingValue,
	getEndingValue,
	handleTableChange,
	form,
	fetchingData,
	handleClear,
	categories,
	type,
	items,
	downloadURL,
	pageSize,
	intialPageSizeOptions,
	initialPageSize,
	currentPage,
	setSearchKey,
	downloadBillingItems }) => {
	const [modalVisible, setModalVisible] = useState(false);
	// const { voucherHeads = [] } = globalRedux;
	return (
		<Row>
			<Col span={24} style={{ padding: 20 }}>
				<Modal width={'90%'} title="Sales Report Preview" open={modalVisible} footer={null} onCancel={() => setModalVisible(false)}>
					<iframe src={downloadURL ? downloadURL : ''} style={{ width: '100%', height: '70vh' }} />
				</Modal>
				<TableComponent
					loading={fetchingData}
					className="custom-table"
					style={{ width: '100%' }}
					rowKey={(record) => record._id}
					dataSource={filteredData}
					title={() => (
						<Row gutter={[10, 10]}>
							<Col xl={12} md={12}>
								<Form
									labelAlign="left"
									name="unit-sections"
									className="required_in_right"
									form={form}
									onFinish={getBillingItems}
									{...layer1FormCol}>
									<Row gutter={[20, 0]}>
										<Col xl={12} md={12}>
											<Row>
												<Col span={24}>
													<Form.Item
														label="Category"
														name="categoryId"
														rules={[
															{
																required: false,
																message: 'This Field is required!',
															},
														]}>
														<Select allowClear placeholder="Select Category" style={{ width: '100%' }}>
															{categories?.map((category) => (
																<Select.Option key={category?._id} value={category?._id}>
																	{category?.categoryName}
																</Select.Option>
															))}
														</Select>
													</Form.Item>
												</Col>
												<Col span={24}>
													<Form.Item
														label="Item"
														name="itemId"
														rules={[
															{
																required: false,
																message: 'This Field is required!',
															},
														]}>
														<Select
															allowClear
															showSearch
															placeholder="Select Item"
															optionFilterProp="children"
															filterOption={(input, option) =>
																option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
															}>
															{items.map((item) => (
																<Select.Option key={item._id} value={item._id}>
																	{item.itemName}
																</Select.Option>
															))}
														</Select>
													</Form.Item>
												</Col>
											</Row>
										</Col>
										<Col xl={12} md={12}>
											<Row>
												<Col span={24}>
													<Form.Item
														label="From Date"
														name="fromDate"
														initialValue={moment()}
														rules={[
															{
																required: true,
																message: 'This Field is required!',
															},
														]}>
														<DatePicker format={DATE_FORMAT.DD_MM_YYYY} style={{ width: '100%' }} />
													</Form.Item>
												</Col>
												<Col span={24}>
													<Form.Item
														label="To Date"
														name="toDate"
														initialValue={moment()}
														rules={[
															{
																required: true,
																message: 'This Field is required!',
															},
														]}>
														<DatePicker format={DATE_FORMAT.DD_MM_YYYY} style={{ width: '100%' }} />
													</Form.Item>
												</Col>
											</Row>
										</Col>
										<Col xl={24} md={24}>
											<Row gutter={[10, 0]}>
												<Col span={24}>
													<Row gutter={[10, 10]}>
														<Col span={8}>
															<Button style={{ width: '100%' }} loading={fetchingData} danger onClick={handleClear}>
																Clear
															</Button>
														</Col>
														<Col span={8}>
															<Button style={{ width: '100%' }} loading={fetchingData} type="primary" htmlType="submit">
																Submit
															</Button>
														</Col>
														<Col span={8}>
															{filteredData?.length > 0 && (
																<Button loading={fetchingData} title='Export to Excel' type="primary" icon={<FileExcelOutlined />} onClick={downloadBillingItems}>
																</Button>
															)}
														</Col>
														<Col span={12}>
															<Input
																placeholder="Search Item"
																suffix={<AiOutlineSearch />}
																style={{ height: '30px' }}
																onChange={({ target: { value } }) => setSearchKey(value)}
															/>
														</Col>
													</Row>
												</Col>
											</Row>
										</Col>
									</Row>
								</Form>
							</Col>
						</Row>
					)}
					{...{
						columns: column,
						pagination: { current: currentPage, pageSize: pageSize, position: ['none', 'none'] },
						...(!!filteredData?.length && {
							footer: () => (
								<Row justify="space-between">
									<Col md={16}>
										{!!filteredData?.length &&
											`Showing ${getStartingValue()} - ${getEndingValue()} of ${filteredData?.length} Data`}
									</Col>
									<Col md={8}>
										<div style={{ textAlign: 'right' }}>
											<Pagination
												pageSizeOptions={intialPageSizeOptions}
												defaultPageSize={initialPageSize}
												showSizeChanger={true}
												total={filteredData?.length}
												onChange={handleTableChange}
												responsive
											/>
										</div>
									</Col>
								</Row>
							),
						}),
					}}
				/>
			</Col>
		</Row>
	);
};

export default AdditionalReportNewPresentational;
