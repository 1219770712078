import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { Button, Select, Modal, Drawer, Row, Col, Input, Form, Divider } from 'antd';
// import { useWindowSize } from "react-use";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import TableComponent from 'components/table-component';
import { convertToIndianRupees } from 'helpers';
import { DATE_FORMAT, PAYMENT_MODES } from 'constants/app-constants';
import MiniBillingsList from 'pages/billings/mini-billings-list';
import Timer from './timer';

const { confirm } = Modal;

const layer1FormCol = {
	labelCol: {
		span: 10,
	},
	wrapperCol: {
		span: 14,
	},
};

const layer1FormCol1 = {
	labelCol: {
		span: 12,
	},
	wrapperCol: {
		span: 12,
	},
};

const AddBillingPresentational = ({
	state,
	setState,
	handleSubmit,
	columns,
	loading,
	editData,
	form,
	customers,
	totalAmount,
	setCustomerAddModal,
	handleItemAdd,
	tableData,
	totalQty,
	totalItem,
	billItemLoading,
	items,
	inputRef,
	receivedAmountForm,
	receivedModal,
	setReceivedModal,
	receivedValue,
	cashValue,
	gpayValue,
	cardValue,
	paymentModeValue,
	handleManualAdd,
	searchKey,
	setSearchKey,
	showData,
	setShowData,
}) => {
	const buttonRef = React.useRef();
	const cashRef = React.useRef();
	const [selectedItem, setSelectedItem] = useState(null);
	const loginRedux = useSelector((state) => state.loginRedux);
	const handle = useFullScreenHandle();
	// const { height } = useWindowSize();
	// const scrollHeight = height - 240 - 292;

	const receivedValueIsValid =
		totalAmount <=
		eval(parseFloat(cashValue || 0).toFixed(2) || 0) +
			eval(parseFloat(gpayValue || 0).toFixed(2) || 0) +
			eval(parseFloat(cardValue || 0).toFixed(2) || 0);
	
	const showConfirm = useCallback(() => {
		confirm({
			title: 'Do you want to close this window?',
			icon: <ExclamationCircleOutlined />,
			content: 'You will be lost all the details you have entered here.',
			onOk() {
				setState((state) => ({ ...state, visible: false }));
			},
			onCancel() {},
		});
	}, [setState]);

	useEffect(() => {
		receivedModal &&
			setTimeout(() => {
				cashRef?.current?.focus();
			}, 10);
	}, [cashRef, receivedModal]);

	const filteredData = useMemo(() => {
		if (searchKey === '') {
			return items;
		}
		return items
			.filter((item) => {
				return (
					(item?.barCode || '')?.toLowerCase() === searchKey.toLowerCase() ||
					(item?.itemName || '')?.toString().toLowerCase().includes(searchKey.toLowerCase())
				);
			})
			?.sort((a, b) => parseInt(a?.barCode, 10) - parseInt(b?.barCode, 10));
	}, [items, searchKey]);

	return (
		<Drawer
			maskClosable={false}
			title={<Timer />}
			placement="right"
			width={'100%'}
			open={state?.visible}
			destroyOnClose
			className="add_billing"
			onClose={showConfirm}
			getContainer={false}
			footer={
				<Row
					style={{
						width: '100%',
					}}>
					<Col
						xl={{
							span: 14,
						}}>
						<Row gutter={[10, 10]} className="billing_footer" style={{ width: '100%' }} justify="space-between">
							<Col>
								<div className="label_value">
									<span className="label">Total Items:</span> <span className="value">{totalItem}</span>
								</div>
							</Col>
							<Col>
								<div className="label_value">
									<span className="label">Total Qty:</span> <span className="value">{totalQty}</span>
								</div>
							</Col>
							<Col>
								<div className="label_value">
									<span className="label">Total Amount:</span> <span className="value">₹{convertToIndianRupees(totalAmount)}</span>
								</div>
							</Col>
						</Row>
					</Col>
					<Col
						xl={{
							span: 10,
						}}>
						<Row gutter={[10, 10]} style={{ width: '100%' }} justify="end">
							<Col>
								<Button onClick={() => setState({ ...state, visible: false })}>Cancel</Button>
							</Col>
							<Col>
								<Button
									loading={loading}
									type="primary"
									disabled={ !receivedValueIsValid}
									onClick={() => tableData?.length > 0 && receivedAmountForm?.submit()}
								>
									{`${editData ? 'Update' : 'Submit'}`}
								</Button>
							</Col>
						</Row>
					</Col>
				</Row>
			}>
			<FullScreen handle={handle}>
				<Row gutter={[20, 10]} justify="start">
					<Col xl={18} md={18}>
						<Row
							gutter={[20, 10]}
							style={{}}>
							<Col span={24}>
								<Button
									style={{
										display: 'none',
									}}
									ref={buttonRef}
									onClick={handle?.enter}
								>
									Enter Fullscreen
								</Button>
								{showData ? (
									<Select
										placeholder="Enter Barcode or Itemname"
										style={{
											width: '100%',
											fontSize: '1rem !important',
											height: 50,
											fontWeight: 'bold',
										}}
										defaultActiveFirstOption={false}
										showSearch
										optionFilterProp="children"
										autoFocus
										className="barcode_input"
										ref={inputRef}
										filterOption={false}
										value={selectedItem}
										onSearch={setSearchKey}
										onInputKeyDown={handleManualAdd}
										onChange={(value) => {
											handleItemAdd(value);
											setSelectedItem(null);
										}}>
										{showData &&
											filteredData?.map((item) => (
												<Select.Option key={item?.barCode} value={item?._id} id={item?.itemName}>
													<section>
														<Col
															style={{
																fontWeight: 'bold',
																fontSize: '1.1rem',
															}}>{`${item?.itemName} - ${item?.barCode || ''}`}</Col>
														<Col style={{ fontWeight: 'bold' }}>{convertToIndianRupees(item?.rate)}</Col>
													</section>
												</Select.Option>
											))}
									</Select>
								) : null}
							</Col>
							<Col
								span={24}
								style={
									{
										// height: "53vh",
										// overflowY: "scroll",
									}
								}>
								<TableComponent
									{...{
										columns,
										dataSource: tableData,
										pagination: false,
										loading: billItemLoading,
									}}
								/>
							</Col>
						</Row>
					</Col>
					<Col xl={6} md={6}>
						<Row justify="space-between">
							<Row className="total_container" justify="space-between" style={{ marginBottom: 20, padding: 10 }}>
								<Col
									className="bold"
									style={{
										fontSize: '2rem',
									}}>
									₹
								</Col>
								<Col
									className="bold"
									style={{
										fontSize: '2rem',
									}}>
									{convertToIndianRupees(totalAmount, false)}
								</Col>
							</Row>
							<Col xl={24} md={24}>
								<Form
									labelAlign="left"
									name="add_billing_form"
									className="required_in_right"
									form={receivedAmountForm}
									onFinish={() => {
										if (receivedValueIsValid) {
											handleSubmit({
												...form?.getFieldsValue(),
												...receivedAmountForm?.getFieldsValue(),
											});
										} else {
											alert("Received Value shouldn't be less than Total Amount!!");
										}
									}}
									// onFinish={(values) => (paymentModeValue === 'Cash' ? setReceivedModal(true) : handleSubmit(values))}
									{...layer1FormCol}>
									<Row gutter={[20, 0]}>
										<Col xl={24} md={24}>
											<Form.Item
												label="Mobile:"
												name="mobile"
												rules={[
													{
														required: false,
														message: 'This Field is required!',
													},
												]}
												wrapperCol={24}>
												<Input maxLength={10} style={{ width: '100%', textAlign: 'right'}} placeHolder="Mobile No" />
											</Form.Item>
										</Col>
										<Col xl={24} md={24}>
											<Row align="middle" className="label_value_container">
												<Col span={10} className="label">
													Bill Amount:
												</Col>
												<Col
													span={14}
													className="value"
													style={{
														background: '#1f3667',
													}}>
													{parseFloat(totalAmount)?.toFixed(2)}
												</Col>
											</Row>
										</Col>
										<Col xl={24} md={24}>
											<Form.Item
												label="Cash:"
												name="cash"
												rules={[
													{
														required: true,
														message: 'This Field is required!',
													},
												]}
												wrapperCol={24}>
												<Input
													style={{
														width: '100%',
														fontSize: '1.2rem',
														textAlign: 'right',
													}}
													placeHolder="0"
													autoFocus
													ref={cashRef}
													onKeyDown={(e) => {
														if (e.key === 'Enter') {
															receivedAmountForm?.submit({
																...receivedAmountForm?.getFieldsValue(),
															});
														}
													}}
												/>
											</Form.Item>
										</Col>
										<Col xl={24} md={24}>
											<Form.Item
												label="Gpay:"
												name="gpay"
												rules={[
													{
														required: false,
														message: 'This Field is required!',
													},
												]}
												wrapperCol={24}>
												<Input
													style={{
														width: '100%',
														fontSize: '1.2rem',
														textAlign: 'right',
													}}
													placeHolder="0"
												/>
											</Form.Item>
										</Col>
										<Col xl={24} md={24}>
											<Form.Item
												label="Card:"
												name="card"
												rules={[
													{
														required: false,
														message: 'This Field is required!',
													},
												]}
												wrapperCol={24}>
												<Input
													style={{
														width: '100%',
														fontSize: '1.2rem',
														textAlign: 'right',
													}}
													placeHolder="0"
												/>
											</Form.Item>
										</Col>
										<Col xl={24} md={24}>
											<Row align="middle" className="label_value_container">
												<Col span={10} className="label">
													Received:
												</Col>
												<Col
													span={14}
													className="value"
													style={{
														background: '#1f3667',
													}}>
													{eval(parseFloat(cashValue || 0).toFixed(2) || 0) +
														eval(parseFloat(gpayValue || 0).toFixed(2) || 0) +
														eval(parseFloat(cardValue || 0).toFixed(2) || 0)}
												</Col>
											</Row>
										</Col>
										<Col xl={24} md={24}>
											<Row align="middle" className="label_value_container">
												<Col span={10} className="label">
													Returned:
												</Col>
												<Col
													span={14}
													className="value"
													style={{
														background: '##51882E',
													}}>
													{parseFloat(
														Math.abs(
															(totalAmount || 0) -
															(eval(parseFloat(cashValue || 0).toFixed(2) || 0) +
																eval(parseFloat(gpayValue || 0).toFixed(2) || 0) +
																eval(parseFloat(cardValue || 0).toFixed(2) || 0))
														)
													).toFixed(2)}
												</Col>
											</Row>
										</Col>
										<Col xl={24} md={24} style={{ paddingBottom: 10 }}>
											<Divider />
										</Col>
										<Col xl={24} md={24}>
											<Form.Item
												label="Bill No"
												name="billNo"
												rules={[
													{
														required: false,
														message: 'This Field is required!',
													},
												]}>
												<Input style={{ width: '100%', textAlign: 'right' }} disabled className="billing_disabled_input" />
											</Form.Item>
										</Col>
										<Col xl={24} md={24}>
											<Form.Item
												label="Bill Date"
												name="date"
												initialValue={moment().format(DATE_FORMAT.DD_MM_YYYY)}
												rules={[
													{
														required: false,
														message: 'This Field is required!',
													},
												]}>
												<Input style={{ width: '100%', textAlign: 'right' }} disabled className="billing_disabled_input" />
											</Form.Item>
										</Col>
										<Col xl={24} md={24}>
											<Form.Item
												label="Cashier"
												name="cashier"
												initialValue={`${loginRedux?.firstName} ${loginRedux?.lastName}`}
												rules={[
													{
														required: false,
														message: 'This Field is required!',
													},
												]}>
												<Input style={{ width: '100%', textAlign: 'right' }} disabled className="billing_disabled_input" />
											</Form.Item>
										</Col>
										<Col xl={24} md={24}>
											<Form.Item
												label="Location"
												name="location"
												rules={[
													{
														required: false,
														message: 'This Field is required!',
													},
												]}>
												<Input style={{ width: '100%', textAlign: 'right' }} disabled className="billing_disabled_input" />
											</Form.Item>
										</Col>
										<Col xl={24} md={24}>
											<Form.Item
												label="Terminal"
												name="terminal"
												rules={[
													{
														required: false,
														message: 'This Field is required!',
													},
												]}>
												<Input style={{ width: '100%', textAlign: 'right' }} disabled className="billing_disabled_input" />
											</Form.Item>
										</Col>
										<Col xl={24} md={24} style={{ paddingBottom: 10 }}>
											<Divider />
										</Col>
									</Row>
								</Form>
							</Col>
						</Row>
					</Col>
				</Row>
			</FullScreen>
			{/* <Modal
				width="30%"
				open={receivedModal}
				title={'Cash Details'}
				onOk={receivedAmountForm?.submit}
				onCancel={() => setReceivedModal(false)}
				getContainer={false}
				className="cash_details_modal"
				okButtonProps={{
					type: 'primary',
					disabled: !receivedValueIsValid,
				}}
				cancelButtonProps={{
					style: {
						display: 'none',
					},
				}}>
				<Form
					labelAlign="left"
					name="add-inventory"
					className="required_in_right"
					form={receivedAmountForm}
					horizontal
					onFinish={() => {
						if (receivedValueIsValid) {
							setReceivedModal(false);
							handleSubmit({
								...form?.getFieldsValue(),
								...receivedAmountForm?.getFieldsValue(),
							});
						} else {
							alert("Received Value shouldn't be less than Total Amount!!");
						}
					}}
					{...layer1FormCol1}>
					<Row gutter={[20, 0]}>
						<Col xl={24} md={24}>
							<Row align="middle" className="label_value_container">
								<Col span={12} className="label">
									Mobile:
								</Col>
								<Col
									span={12}
									className="value"
									style={{
										background: '#fff',
										padding: 0,
									}}>
									<Form.Item
										label=""
										className="hideFormLabel"
										name="mobile"
										rules={[
											{
												required: false,
												message: 'This Field is required!',
											},
										]}
										wrapperCol={24}>
										<Input
											maxLength={10}
											style={{
												width: '100%',
												fontSize: '1.2rem',
											}}
											placeHolder="Mobile No"
										/>
									</Form.Item>
								</Col>
							</Row>
							<Row align="middle" className="label_value_container">
								<Col span={12} className="label">
									Bill Amount:
								</Col>
								<Col
									span={12}
									className="value"
									style={{
										background: '#1f3667',
									}}>
									{parseFloat(totalAmount)?.toFixed(2)}
								</Col>
							</Row>
							<Row align="middle" className="label_value_container">
								<Col span={12} className="label">
									Cash:
								</Col>
								<Col
									span={12}
									className="value"
									style={{
										background: '#fff',
										padding: 0,
									}}>
									<Form.Item
										label=""
										className="hideFormLabel"
										name="cash"
										rules={[
											{
												required: true,
												message: 'This Field is required!',
											},
										]}
										wrapperCol={24}>
										<Input
											style={{
												width: '100%',
												fontSize: '1.2rem',
												textAlign: 'right',
											}}
											placeHolder="0"
											autoFocus
											ref={cashRef}
											onKeyDown={(e) => {
												if (e.key === 'Enter') {
													receivedAmountForm?.submit({
														...receivedAmountForm?.getFieldsValue(),
													});
												}
											}}
										/>
									</Form.Item>
								</Col>
							</Row>
							<Row align="middle" className="label_value_container">
								<Col span={12} className="label">
									Gpay:
								</Col>
								<Col
									span={12}
									className="value"
									style={{
										background: '#fff',
										padding: 0,
									}}>
									<Form.Item
										label=""
										className="hideFormLabel"
										name="gpay"
										rules={[
											{
												required: false,
												message: 'This Field is required!',
											},
										]}
										wrapperCol={24}>
										<Input
											style={{
												width: '100%',
												fontSize: '1.2rem',
												textAlign: 'right',
											}}
											placeHolder="0"
										/>
									</Form.Item>
								</Col>
							</Row>
							<Row align="middle" className="label_value_container">
								<Col span={12} className="label">
									Card:
								</Col>
								<Col
									span={12}
									className="value"
									style={{
										background: '#fff',
										padding: 0,
									}}>
									<Form.Item
										label=""
										className="hideFormLabel"
										name="card"
										rules={[
											{
												required: false,
												message: 'This Field is required!',
											},
										]}
										wrapperCol={24}>
										<Input
											style={{
												width: '100%',
												fontSize: '1.2rem',
												textAlign: 'right',
											}}
											placeHolder="0"
										/>
									</Form.Item>
								</Col>
							</Row>
							<Row align="middle" className="label_value_container">
								<Col span={12} className="label">
									Received:
								</Col>
								<Col
									span={12}
									className="value"
									style={{
										background: '#1f3667',
									}}>
									{eval(parseFloat(cashValue || 0).toFixed(2) || 0) +
										eval(parseFloat(gpayValue || 0).toFixed(2) || 0) +
										eval(parseFloat(cardValue || 0).toFixed(2) || 0)}
								</Col>
							</Row>
							<Row align="middle" className="label_value_container">
								<Col span={12} className="label">
									Returned:
								</Col>
								<Col
									span={12}
									className="value"
									style={{
										background: '##51882E',
									}}>
									{parseFloat(
										Math.abs(
											(totalAmount || 0) -
												(eval(parseFloat(cashValue || 0).toFixed(2) || 0) +
													eval(parseFloat(gpayValue || 0).toFixed(2) || 0) +
													eval(parseFloat(cardValue || 0).toFixed(2) || 0))
										)
									).toFixed(2)}
								</Col>
							</Row>
						</Col>
					</Row>
				</Form>
			</Modal> */}
		</Drawer>
	);
};

export default AddBillingPresentational;
