import React from "react";
import { Row, Col, Pagination, Button, Form, Select } from "antd";
import TableComponent from "components/table-component";
import { PrinterOutlined } from "@ant-design/icons";
import moment from "moment";
import { DATE_FORMAT } from "constants/app-constants";
import DatePicker from "components/date-picker";
import ExcelDownload from "components/excel-download";
import PdfDownload from "components/pdf-download";

const layer1FormCol = {
	labelCol: {
		span: 8,
	},
	wrapperCol: {
		span: 16,
	},
};

const SalesByItemReportReportPresentational = ({
	column,
	filteredData,
	handleTableChange,
	getStartingValue,
	getEndingValue,
	pageSize,
	intialPageSizeOptions,
	initialPageSize,
	currentPage,
	fetchingData,
	getBillingItems,
	downloadSalesByItems,
	form,
	handleClear,
	setSelectedRecordToPrint,
	exportData,
	items,
}) => {
	return (
		<Row>
			<Col span={24} style={{ padding: 20 }}>
				<TableComponent
					loading={fetchingData}
					className="custom-table"
					style={{ width: "100%" }}
					rowKey={(record) => record._id}
					dataSource={filteredData}
					title={() => (
						<Row gutter={[10, 10]}>
							<Col xl={12} md={12}>
								<Form
									labelAlign="left"
									name="unit-sections"
									className="required_in_right"
									form={form}
									onFinish={getBillingItems}
									{...layer1FormCol}
								>
									<Row gutter={[20, 0]}>
										<Col xl={12} md={12}>
											<Row>
												<Col span={24}>
													<Form.Item
														label="Item Name"
														name="itemId"
														// initialValue={moment()}
														rules={[
															{
																required: false,
																message: "This Field is required!",
															},
														]}
													>
														<Select
															allowClear
															showSearch
															placeholder="Select Item"
															optionFilterProp="children"
															filterOption={(input, option) =>
																option.children
																	.toLowerCase()
																	.indexOf(input.toLowerCase()) >= 0
															}
														>
															{items.map((item) => (
																<Select.Option key={item._id} value={item._id}>
																	{item.itemName}
																</Select.Option>
															))}
														</Select>
													</Form.Item>
												</Col>
												<Col span={24}>
													<Form.Item
														label="From Date"
														name="fromDate"
														initialValue={moment()}
														rules={[
															{
																required: true,
																message: "This Field is required!",
															},
														]}
													>
														<DatePicker
															format={DATE_FORMAT.DD_MM_YYYY}
															style={{ width: "100%" }}
														/>
													</Form.Item>
												</Col>
												<Col span={24}>
													<Form.Item
														label="To Date"
														name="toDate"
														initialValue={moment()}
														rules={[
															{
																required: true,
																message: "This Field is required!",
															},
														]}
													>
														<DatePicker
															format={DATE_FORMAT.DD_MM_YYYY}
															style={{ width: "100%" }}
														/>
													</Form.Item>
												</Col>
											</Row>
										</Col>
										<Col xl={24} md={24}>
											<Row gutter={[10, 0]}>
												<Col span={24}>
													<Row gutter={[10, 10]}>
														<Col span={8}>
															<Button
																style={{ width: "100%" }}
																loading={fetchingData}
																danger
																onClick={handleClear}
															>
																Clear
															</Button>
														</Col>
														<Col span={8}>
															<Button
																style={{ width: "100%" }}
																loading={fetchingData}
																type="primary"
																htmlType="submit"
															>
																Submit
															</Button>
														</Col>
														{filteredData?.length > 0 && (
															<Col span={8}>
																<Button
																	icon={<PrinterOutlined />}
																	onClick={() =>
																		setSelectedRecordToPrint({
																			...form.getFieldsValue(),
																			data: filteredData,
																		})
																	}
																	type="primary"
																	style={{ width: "100%" }}
																>
																	Print
																</Button>
															</Col>
														)}
														<Col span={16}>
															<Row gutter={[10, 10]}>
																<Col>
																	<ExcelDownload {...{ ...exportData }} />
																</Col>
																<Col>
																	<Button
																		style={{ width: "100%" }}
																		loading={fetchingData}
																		type="primary"
																		onClick={downloadSalesByItems}
																	>
																		PDF Download
																	</Button>
																</Col>
															</Row>
														</Col>
													</Row>
												</Col>
											</Row>
										</Col>
									</Row>
								</Form>
							</Col>
						</Row>
					)}
					{...{
						columns: column,
						pagination: {
							current: currentPage,
							pageSize: pageSize,
							position: ["none", "none"],
						},
						...(!!filteredData?.length && {
							footer: () => (
								<Row justify="space-between">
									<Col span={12}>
										{!!filteredData?.length &&
											`Showing ${getStartingValue()} - ${getEndingValue()} of ${filteredData?.length
											} Data`}
									</Col>
									<Col span={12}>
										<div style={{ textAlign: "right" }}>
											<Pagination
												pageSizeOptions={intialPageSizeOptions}
												defaultPageSize={initialPageSize}
												showSizeChanger={true}
												total={filteredData?.length}
												onChange={handleTableChange}
												responsive
											/>
										</div>
									</Col>
								</Row>
							),
						}),
					}}
				/>
			</Col>
		</Row>
	);
};

export default SalesByItemReportReportPresentational;
